body {
  background: linear-gradient(rgb(42, 88, 134) 0, #2B5A88 220px, #7e2e2e 320px, #7e2e2e 500px, #1f3f5f 600px, #13273c);
  color: #ddd;
}

a {
  color: #fff;

  &:hover {
    color: #5cafff;
  }
}

header {
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: -5px;

  img {
    width: 100%;
  }
}

header, .app {
  max-width: 1310px;
}

.app {
  margin: 0 auto;
  width: 100%;
  background: linear-gradient(#2B5A88, #13273c);

  @media screen and (min-width: 768px) {
    padding: 1px 40px;
  }
}

.subfolders {
  li {
    margin: 5px 0;
    background: rgba(255, 255, 255, 0.4);
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.3s;
    flex: 1 0 100%;
    box-sizing: border-box;

    @media screen and (min-width: 768px) {
      flex: 0 0 calc(50% - 20px);
      margin: 10px;
    }

    a {
      color: #222;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.5);

      a {
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 1310px) {
  body {
    background-size: contain;
  }
}
