body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background: #212121;
	color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color : #dddddd;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.app {
  width: 80vw;
  max-width: 1200px;
  min-height: 100vh;
  margin: 20px auto;
  padding: 1px 20px;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.folder {
  h1 {
    color: #f0f0f0;
  }

  ul.breadcrumb {
    list-style: none;
  }

  ul.breadcrumb li {
    display: inline;
  }

  ul.breadcrumb li+li:before {
    padding: 0.5em;
    content: "/\00a0";
  }
}

.subfolders {
  display: flex;
  flex-flow: row wrap;

  li {
    flex: 0 0 calc(50% - 20px);
    margin: 10px 0;

    a {
      display: flex;
      align-items: center;
      padding: 5px 0;
      font-weight: bold;
    }

    .cover {
      width: 50px;
      min-height: 50px;
      vertical-align: middle;
      margin-right: 1em;
    }
  }
}

.contents {
  @media screen and (max-width: 768px) {
    margin: 0 -20px;
  }
}

.loader {
  text-align: center;
  margin: 25px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 15px auto;
    border-radius: 50%;
    border: 6px solid transparent;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
